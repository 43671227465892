import React from 'react';

import styles from './styles.module.scss';

interface Props {
  imageSrc: string;
  title: string;
  list: {
    icon: React.ReactNode;
    title: string;
  }[];
}

export class SegmentOption extends React.PureComponent<Props> {
  render() {
    const { imageSrc, title, list } = this.props;

    return (
      <div className={styles.container}>
        <header className={styles.header}>
          <i className={styles.image}>
            <img src={imageSrc} alt="" />
          </i>
          <h4 className={styles.title}>{title}</h4>
        </header>
        <ul className={styles.list}>
          {list.map((i) => (
            <li className={styles.item} key={i.title}>
              <i className={styles.icon}>{i.icon}</i>
              <span className={styles.itemTitle}>{i.title}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
