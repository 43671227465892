import React from 'react';
import styles from './MainContent.module.scss';

interface Props {
  className?: string;
}

export const MainContent: React.FC<Props> = ({ children, className = '' }) => {
  return <section className={`${styles.container} ${className}`}>{children}</section>;
};
