import { Table } from '../../models/Table';
import { createAction } from '../../utils/actions';
import { tableConstants } from './constants';

export interface GetData {
  getData: (tableData: Table) => void;
}

export const tableActions = {
  getData(tableData: Table) {
    return createAction(tableConstants.GET_TABLE_DATA, tableData);
  },
};
