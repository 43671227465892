import { NoMatchPage } from 'pages/NoMatchPage';
import { DefaultPage } from 'pages/DefaultPage';
import { MatchedAudience } from 'pages/MatchedAudience';
import { CampaignAudience } from 'pages/CampaignAudience';
import { SegmentedAudience } from 'pages/SegmentedAudience';
import { GeofarmedAudience } from 'pages/GeofarmedAudience';
import { RetargetedAudience } from '../pages/retargetedAudience';

export type Route = {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
  private?: boolean;
};

export const routes: Route[] = [
  {
    path: '/',
    exact: true,
    component: DefaultPage,
  },
  {
    path: '/matched',
    exact: true,
    component: MatchedAudience,
  },
  {
    path: '/campaign',
    exact: true,
    component: CampaignAudience,
  },
  {
    path: '/segmented',
    exact: true,
    component: SegmentedAudience,
  },
  {
    path: '/retargeted',
    exact: true,
    component: RetargetedAudience,
  },
  {
    path: '/geo-farmed',
    exact: true,
    component: GeofarmedAudience,
  },
  {
    path: '*',
    exact: true,
    component: NoMatchPage,
  },
];
