import React from 'react';

import styles from './steps.module.scss';

interface Props {
  className?: string;
  step: number;
}

export class Steps extends React.PureComponent<Props> {
  render() {
    const { className = '', step } = this.props;

    return (
      <div className={`${styles.container} ${className}`}>
        <ul className={styles.steps}>
          {[0, 1, 2, 3, 4].map((i) => (
            <li className={`${styles.step} ${i <= step ? '_active' : ''}`} key={i} />
          ))}
        </ul>
        <p className={`text-1 ${styles.status} ${step + 1 === 5 ? '_full' : ''}`}>
          <span className={styles.statusNumber}>{step}</span> out of{' '}
          <span className={styles.statusNumber}>5</span> required columns matched
        </p>
      </div>
    );
  }
}
