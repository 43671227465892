import React, { Component } from 'react';
import { Button, Icon, Tabs, TextField } from 'factor';

import styles from './styles.module.scss';

import { Sidebar } from '../../components/Sidebar';
import { MainContent } from '../../components/MainContent';
import { TestTable } from '../../components/Table';
import { Layout } from '../../components/Layout';

import { Tab } from '../../models/Tab';
import { SidebarFooter } from '../../components/sidebarFooter';
import { DatePicker } from '../../components/datePicker';

const TABS = [
  { title: 'Integration', value: 'Integration' },
  { title: 'Email Instruction', value: 'Email Instruction' },
];

interface State {
  tab: string;
  websiteUrl: string;
  websiteUrl2: string;
}

export class RetargetedAudience extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      tab: TABS[0].value,
      websiteUrl: '',
      websiteUrl2: '',
    };
  }

  handleTabsChange = (item: Tab) => this.setState({ tab: item.value });

  handleTextFieldChange = (label: string) => (v: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [label]: v,
    }));
  };

  render() {
    const { tab, websiteUrl, websiteUrl2 } = this.state;

    const sidebar = (
      <Sidebar
        header={{
          title: 'Retargeted Audience',
          icon: <Icon name="Retargeted" />,
        }}
      >
        <div className={styles.row}>
          <Tabs className={styles.tabs} items={TABS} value={tab} onChange={this.handleTabsChange} />
        </div>
        <DatePicker className={styles.dateRange} />
        <Button className={`btn-square _conflower-blue ${styles.copyCode}`}>
          <span className="btn-square__prefix">
            <Icon name="Copy" />
          </span>
          Copy Pixel Code to Clipboard
        </Button>
        <p className="text-2 mb-3">
          Copy and paste the pixel code into the bottom of the header section of your site, just
          above the &lt;/head&gt; tag. Install the base code on every page of your website.
        </p>
        <ul className={styles.urls}>
          <li className={styles.url}>
            <i className={`${styles.urlStatus} ${styles.success}`} />
            <TextField
              className={styles.urlTextField}
              label="URL of the website"
              value={websiteUrl}
              onChange={this.handleTextFieldChange('websiteUrl')}
              withBorder
            />
            <button type="button" className={`btn-close _1 ${styles.urlRemove}`} />
          </li>
          <li className={styles.url}>
            <i className={`${styles.urlStatus} ${styles.error}`} />
            <TextField
              className={styles.urlTextField}
              label="URL of the website"
              value={websiteUrl2}
              onChange={this.handleTextFieldChange('websiteUrl2')}
              withBorder
            />
            <button type="button" className={`btn-close _1 ${styles.urlRemove}`} />
          </li>
        </ul>
        <div className={styles.buttons}>
          <Button className={`btn-round _gray ${styles.btn1}`}>
            <span className="btn-round__prefix">+</span>
            Add New URL
          </Button>
          <Button className={`btn-square _gray ${styles.btn2}`}>Send Test Traffic</Button>
        </div>
        <SidebarFooter hasAudienceNameField submit={{ label: 'Add Retargeted Audience' }} />
      </Sidebar>
    );

    return (
      <Layout sidebar={sidebar}>
        <MainContent>
          <TestTable />
        </MainContent>
      </Layout>
    );
  }
}
