import React from 'react';
import { Icon, Opener, SelectList, TextField } from 'factor';

import styles from './styles.module.scss';

import exampleImage2 from '../../img/example/example_logo_2.png';
import exampleImage1 from '../../img/example/example_logo_1.png';
import { Option } from '../../../../models/Option';
import { SegmentOption } from '../segmentOption';
import { ReactComponent as SegmentIcon } from '../../svg/icon.svg';

const OPTION_LIST = [
  {
    icon: <SegmentIcon />,
    title: 'Standard Segments Long Long Very Very Very Long',
  },
  {
    icon: <Icon name="Segmented" />,
    title: 'Sports Cars ($ 0.75)',
  },
];

const OPTIONS = [
  {
    label: 'IQM DMP 1',
    reactLabel: <SegmentOption imageSrc={exampleImage1} title="IQM DMP 1" list={OPTION_LIST} />,
    value: '0',
  },
  {
    label: 'Test 1',
    reactLabel: <SegmentOption imageSrc={exampleImage2} title="Test 1" list={OPTION_LIST} />,
    value: 'test',
  },
  {
    label: 'IQM DMP 2',
    reactLabel: <SegmentOption imageSrc={exampleImage1} title="IQM DMP 2" list={OPTION_LIST} />,
    value: '1',
  },
  {
    label: 'IQM DMP 3',
    reactLabel: <SegmentOption imageSrc={exampleImage1} title="IQM DMP 3" list={OPTION_LIST} />,
    value: '2',
  },
];

interface Props {
  getSearchFieldRef?: (el: HTMLElement) => void;
}

interface State {
  search: string;
  listValue: Option | null;
}

export class SearchList extends React.Component<Props, State> {
  searchField!: HTMLElement;

  constructor(props: any) {
    super(props);
    this.state = {
      search: '',
      listValue: null,
    };
  }

  componentDidMount() {
    const { getSearchFieldRef } = this.props;
    this.toggleContent(true);

    if (getSearchFieldRef) {
      getSearchFieldRef(this.searchField);
    }
  }

  handleSearchChange: (v: string) => void = () => {};

  toggleContent: (isOpen?: boolean) => void = () => {};

  handleSearchFieldChange = (v: string) => {
    this.setState({ search: v }, () => this.handleSearchChange(v));
  };

  renderTextField = () => {
    const { search } = this.state;

    const classNames = [styles.searchField];
    if (search) {
      classNames.push('_active');
    }

    return (
      <TextField
        className={classNames.join(' ')}
        iconName="Search"
        label="Search for Segments"
        value={search}
        onChange={this.handleSearchFieldChange}
        inputRef={(el: HTMLElement) => {
          this.searchField = el;
        }}
        withBorder
      />
    );
  };

  handleSelectListChange = (v: Option) => {
    this.setState({ listValue: v });
  };

  getSearchInputHandler = (handleSearchChange: () => void) => {
    this.handleSearchChange = handleSearchChange;
  };

  renderList = () => {
    const { listValue, search } = this.state;

    if (!search) {
      return null;
    }

    return (
      <SelectList
        className={styles.selectList}
        value={listValue}
        options={OPTIONS}
        getSearchInputHandler={this.getSearchInputHandler}
        onChange={this.handleSelectListChange}
      />
    );
  };

  getToggleContent = (toggleContentHandler: (isOpen?: boolean) => {}) => {
    this.toggleContent = toggleContentHandler;
  };

  render() {
    return (
      <Opener
        btn={this.renderTextField()}
        content={this.renderList()}
        getToggleContent={this.getToggleContent}
      />
    );
  }
}
