import React from 'react';

import { Sidebar } from '../../components/Sidebar';
import { MainContent } from '../../components/MainContent';
import { TestTable } from '../../components/Table';
import { Layout } from '../../components/Layout';

const DefaultPage = () => (
  <Layout sidebar={<Sidebar mainpage />}>
    <MainContent>
      <TestTable />
    </MainContent>
  </Layout>
);

export { DefaultPage };
