import React from 'react';
import { Icon, TextField } from 'factor';

import FileUploader from '../../../../components/fileUploader/FileUploader';
import { SetUpMatchedAudience } from '../setUpMatchedAudience';
import { Sidebar } from '../../../../components/Sidebar';

interface State {
  isAudienceUploaded: boolean;
  audienceName: string;
}

export class MatchedAudienceSidebar extends React.PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isAudienceUploaded: false,
      audienceName: '',
    };
  }

  onAudienceUploaded = () => this.setState({ isAudienceUploaded: true });

  handleAudienceNameChange = (v: string) => this.setState({ audienceName: v });

  render() {
    const { isAudienceUploaded, audienceName } = this.state;

    return (
      <Sidebar
        header={{ title: 'Matched Audience', icon: <Icon name="Matched" /> }}
        footerSubmitBtn="Add Matched Audience"
      >
        {!isAudienceUploaded ? (
          <>
            <FileUploader
              className="mb-3"
              browseFileButtonTitle="Upload Audience"
              tooltipParams={{
                label: 'Upload Audience',
              }}
              onFileUploaded={this.onAudienceUploaded}
            />
            <TextField
              label="Audience Name"
              value={audienceName}
              onChange={this.handleAudienceNameChange}
              withBorder
            />
          </>
        ) : (
          <SetUpMatchedAudience />
        )}
      </Sidebar>
    );
  }
}
