import React from 'react';
import { Icon, Button } from 'factor';

import styles from './styles.module.scss';

import { Sidebar } from '../../components/Sidebar';
import { MainContent } from '../../components/MainContent';
import { TestTable } from '../../components/Table';
import { Layout } from '../../components/Layout';
import { Group } from './components/group';
import { SidebarFooter } from '../../components/sidebarFooter';

export const SegmentedAudience = () => {
  const sidebar = (
    <Sidebar
      header={{
        title: 'Segmented Audience',
        icon: <Icon name="Segmented" />,
      }}
    >
      <Group />
      <Button className={`btn-round _gray ${styles.btn} mb-3`}>
        <span className="btn-round__prefix">+</span>
        Add Group
      </Button>
      <SidebarFooter hasAudienceNameField submit={{ label: 'Add Segmented Audience' }} />
    </Sidebar>
  );

  return (
    <Layout sidebar={sidebar}>
      <MainContent>
        <TestTable />
      </MainContent>
    </Layout>
  );
};
