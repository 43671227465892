import React from 'react';

import { MainContent } from '../../components/MainContent';
import { TestTable } from '../../components/Table';
import { Layout } from '../../components/Layout';
import { MatchedAudienceSidebar } from './components/matchedAudienceSidebar';

export const MatchedAudience = () => (
  <Layout sidebar={<MatchedAudienceSidebar />}>
    <MainContent>
      <TestTable />
    </MainContent>
  </Layout>
);
