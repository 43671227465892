import React from 'react';
import { EpochSingleDateRangePicker, TimezonePicker, TimezoneProvider } from 'iqm-framework';

import styles from './styles.module.scss';

interface Props {
  className?: string;
}

interface State {
  dateRange: {
    startDate: Date | null;
    endDate: Date | null;
  };
  timezone: any;
}

export class DatePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dateRange: {
        startDate: null,
        endDate: null,
      },
      timezone: null,
    };
  }

  onTimezoneChanged = (timezone: any) => {
    this.setState({
      timezone,
    });
  };

  onDateRangeChanged = (dateRange: any) => {
    this.setState({
      dateRange,
    });
  };

  onError = () => {};

  render() {
    const { className = '' } = this.props;
    const { dateRange, timezone } = this.state;

    return (
      <div className={`${styles.dateRange} ${className}`}>
        <TimezoneProvider timezone={timezone}>
          <TimezonePicker
            onTimezoneChange={this.onTimezoneChanged}
            tooltipParams={{ label: 'TimezonePicker Tooltip', auto: false, position: 'bottom' }}
          />
          <EpochSingleDateRangePicker
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            onDateRangeChanged={this.onDateRangeChanged}
            onError={this.onError}
            startLabel="Start Date"
            endLabel="End Date"
            className={styles.pickers}
          />
        </TimezoneProvider>
      </div>
    );
  }
}
