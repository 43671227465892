export function getRowClassName(
  initialClassName: string | undefined,
  newClassName: string,
  addClass: boolean,
) {
  if (initialClassName === undefined) {
    if (addClass) {
      return newClassName;
    }
    return undefined;
  }
  if (new RegExp(newClassName).test(initialClassName)) {
    if (addClass) {
      return initialClassName;
    }
    return initialClassName.replace(`${newClassName}`, '').trim();
  }
  if (addClass) {
    return `${initialClassName} ${newClassName}`;
  }
  return initialClassName;
}
