import React from 'react';
import { Select, TextField, Button, Icon } from 'factor';

import styles from './styles.module.scss';
import { Steps } from '../../../../components/steps';
import { Option } from '../../../../models/Option';
import { SidebarFooter } from '../../../../components/sidebarFooter';

const FILE_PARAMS = [
  { label: 'Size', value: '1,2 mb' },
  { label: 'Columns', value: '6' },
  { label: 'Rows', value: '5' },
];

const FIRST_NAME_OPTIONS = [
  { label: 'First Name', value: 'First Name' },
  { label: 'Last Name', value: 'Last Name' },
  { label: 'Address', value: 'Address' },
  { label: 'State', value: 'State' },
  { label: 'ZIP', value: 'ZIP' },
  { label: 'City', value: 'City' },
  { label: 'Gender', value: 'Gender' },
];

const TEST_OPTIONS = [
  { label: 'Option 1', value: 'Option 1' },
  { label: 'Option 2', value: 'Option 2' },
  { label: 'Option 3', value: 'Option 3' },
];

interface State {
  firstName: Option | null;
  lastName: Option | null;
  state: Option | null;
  address: Option | null;
  zip: Option | null;
  city: Option | null;
  gender: Option | null;
  isGenderVisible: boolean;
  audienceName: string;
}

export class SetUpMatchedAudience extends React.PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      firstName: null,
      lastName: null,
      state: null,
      address: null,
      zip: null,
      city: null,
      gender: null,
      isGenderVisible: false,
      audienceName: 'Trump 2020',
    };
  }

  onFirstNameChange = (v: Option) => this.setState({ firstName: v });

  onLastNameChange = (v: Option) => this.setState({ lastName: v });

  onStateChange = (v: Option) => this.setState({ state: v });

  onAddressChange = (v: Option) => this.setState({ address: v });

  onZipChange = (v: Option) => this.setState({ zip: v });

  onCityChange = (v: Option) => this.setState({ city: v });

  onGenderChange = (v: Option) => this.setState({ gender: v });

  handleAudienceNameChange = (v: string) => this.setState({ audienceName: v });

  toggleGender = (isGenderVisible: boolean) => () => {
    this.setState({ isGenderVisible });
  };

  render() {
    const {
      firstName,
      lastName,
      state,
      address,
      zip,
      city,
      gender,
      isGenderVisible,
      audienceName,
    } = this.state;

    return (
      <div className={styles.container}>
        <Steps className={styles.steps} step={0} />
        <div className={styles.file}>
          <header className={styles.fileHeader}>
            <h4 className={styles.fileTitle}>Trump2020.csv</h4>
            <button type="button" className={`btn-close ${styles.fileRemove}`} />
          </header>
          <ul className={styles.fileParams}>
            {FILE_PARAMS.map((i: { label: string; value: string }) => (
              <li className={styles.fileParam} key={i.label}>
                <div className={styles.fileParamLabel}>{i.label}</div>
                <div className={styles.fileParamValue}>{i.value}</div>
              </li>
            ))}
          </ul>
        </div>
        <p className={styles.text}>
          Select system columns from the drop-downs to match columns fom file
        </p>
        <Select
          className="mb-3"
          options={FIRST_NAME_OPTIONS}
          value={firstName}
          onChange={this.onFirstNameChange}
          isSearchable
          label="first_name"
          placeholder={`Select match for "first_name"`}
        />
        <Select
          className="mb-3"
          options={TEST_OPTIONS}
          value={lastName}
          onChange={this.onLastNameChange}
          isSearchable
          label="last_name"
          placeholder={`Select match for "last_name"`}
        />
        <Select
          className="mb-3"
          options={TEST_OPTIONS}
          value={state}
          onChange={this.onStateChange}
          isSearchable
          label="state"
          placeholder={`Select match for "state"`}
        />
        <Select
          className="mb-3"
          options={TEST_OPTIONS}
          value={address}
          onChange={this.onAddressChange}
          isSearchable
          label="address"
          placeholder={`Select match for "address"`}
        />
        <Select
          className="mb-3"
          options={TEST_OPTIONS}
          value={zip}
          onChange={this.onZipChange}
          isSearchable
          label="zip"
          placeholder={`Select match for "ZIP"`}
        />
        <Select
          className="mb-3"
          options={TEST_OPTIONS}
          value={city}
          onChange={this.onCityChange}
          isSearchable
          label="city"
          placeholder={`Select match for "city"`}
        />
        {isGenderVisible && (
          <div className={`${styles.selectRow} mb-3`}>
            <Select
              options={TEST_OPTIONS}
              value={gender}
              onChange={this.onGenderChange}
              isSearchable
              label="gender"
              placeholder={`Select match for "gender"`}
            />
            <button
              type="button"
              className={`btn-close ${styles.fileRemove} ml-1`}
              onClick={this.toggleGender(false)}
            />
          </div>
        )}
        <Button
          className="btn-round _conflower-blue mb-4 ml-auto mr-auto"
          onClick={this.toggleGender(true)}
        >
          <span className="btn-content__icon">
            <Icon name="Plus" />
          </span>
          Add Column
        </Button>
        <div className={styles.name}>
          <TextField
            label="Audience Name"
            value={audienceName}
            onChange={this.handleAudienceNameChange}
            withBorder
          />
        </div>
        <Steps className={styles.steps} step={0} />
        <SidebarFooter submit={{ label: 'Add Matched Audience' }} />
      </div>
    );
  }
}
