import React from 'react';

import styles from './styles.module.scss';
import { Option } from '../../models/Option';

interface Chip extends Option {
  className?: string;
  icon?: React.ReactElement;
}

interface Props {
  item: Chip;
  onRemove?: (item: Option) => void;
}

export const Chip = (props: Props) => {
  const { item, onRemove = () => {} } = props;

  const classNames = [styles.chip];
  if (item.className) {
    classNames.push(item.className);
  }

  return (
    <div className={classNames.join(' ')}>
      {item.icon && <i className={styles.icon}>{item.icon}</i>}
      {item.label}
      <button
        type="button"
        className={`btn-close ${styles.remove}`}
        onClick={() => onRemove(item)}
      />
    </div>
  );
};
