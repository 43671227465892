import React from 'react';
import { TextField, Button } from 'factor';

import styles from './styles.module.scss';

interface Props {
  submit: {
    label: string;
  };
  hasAudienceNameField?: boolean;
}

interface State {
  audienceName: string;
}

export class SidebarFooter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      audienceName: '',
    };
  }

  handleTextFieldChange = (label: string) => (v: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [label]: v,
    }));
  };

  render() {
    const { hasAudienceNameField, submit } = this.props;
    const { audienceName } = this.state;

    return (
      <footer className={styles.footer}>
        {hasAudienceNameField && (
          <TextField
            label="Audience Name"
            value={audienceName}
            onChange={this.handleTextFieldChange('audienceName')}
            withBorder
          />
        )}
        <div className={styles.buttons}>
          <Button className={`btn-square _md _conflower-blue ${styles.btn}`}>Cancel</Button>
          <Button className={`btn-square _md _filled _conflower-blue ${styles.btn}`}>
            {submit.label}
          </Button>
        </div>
      </footer>
    );
  }
}
