import React from 'react';

import styles from './styles.module.scss';

interface Props {
  title: string;
  list: {
    label: string;
    value: string;
  }[];
}

export class ComplexOption extends React.PureComponent<Props> {
  render() {
    const { title, list } = this.props;

    return (
      <div className={styles.container}>
        <header className={`text-1 ${styles.header}`}>{title}</header>
        <ul className={styles.params}>
          {list.map((i) => (
            <li className={styles.param} key={i.label}>
              <h4 className={styles.label}>{i.label}</h4>
              <span>{i.value}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
