import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getRowClassName } from '../../helpers';

import styles from './SelectedCampaigns.module.scss';
import { TableRow } from '../../../models/Table';
import { tableActions } from '../../../store/table/actions';
import { AppState } from '../../../store';

class SelectedCampaignsComponent extends Component<any> {
  handleClear = () => {
    const { tableData: oldTableData, getData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;
    const tableFirstRow = newData.unsortedData;

    newData.unsortedData = {
      ...tableFirstRow,
      selectedAll: false,
    };
    newData.data = tableData.map((i: TableRow) => {
      /* eslint-disable */
      i.selected = false;
      i.rowClassName = getRowClassName(i.rowClassName, '_selected', false);
      /* eslint-enable */
      return { ...i };
    });

    getData(newData);
  };

  render() {
    const { tableData } = this.props;

    const selectedLength = tableData.data.filter((i: TableRow) => i.selected).length;

    return (
      <>
        <div className={styles.selected}>
          <button
            type="button"
            className={`btn-close ${styles.remove}`}
            onClick={this.handleClear}
          />
          {`${selectedLength} Audience${selectedLength > 1 ? 's' : ''} selected`}
        </div>
      </>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    tableData: state.table.tableData,
  };
};

const mapActions = {
  getData: tableActions.getData,
};

const SelectedCampaigns = connect(mapState, mapActions)(SelectedCampaignsComponent);

export default SelectedCampaigns;
