import React from 'react';
import { Link } from 'react-router-dom';
import { TextField } from 'factor';

import styles from './Sidebar.module.scss';

import SelectType from '../SelectType';

interface Props {
  mainpage?: boolean;
  header?: {
    title?: string;
    icon?: React.ReactNode;
  };
  withSearch?: boolean;
  footerSubmitBtn?: string;
}

interface State {
  search: string;
}

class Sidebar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  renderHeader = () => {
    const { header, withSearch } = this.props;

    const { search } = this.state;

    return (
      <header className={styles.header}>
        <div className={styles.headerWrapper}>
          {header && <i className={styles.icon}>{header.icon}</i>}
          <h3 className={styles.title}>{header ? header.title : 'Select Audience Type'}</h3>
          {header && <Link to="/" className="btn-close" />}
        </div>
        {withSearch && (
          <div className={styles.headerSearch}>
            <TextField
              iconName="Search"
              label="Search"
              value={search}
              onChange={({ target }: { target: HTMLInputElement }) =>
                this.setState({ search: target.value })
              }
              withBorder
            />
          </div>
        )}
      </header>
    );
  };

  renderContent = () => {
    const { children } = this.props;

    if (children) {
      return <div className={styles.content}>{children}</div>;
    }
    return <SelectType />;
  };

  render() {
    const { mainpage } = this.props;

    return (
      <div className={`${styles.sidebar} ${mainpage ? '_mainpage' : ''}`}>
        <div className={styles.container}>
          {this.renderHeader()}
          <div className={styles.main}>{this.renderContent()}</div>
        </div>
      </div>
    );
  }
}

export { Sidebar };
