import React from 'react';
import { Option } from '../../models/Option';
import { Chip } from '../Chip';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  items: Option[];
  onRemove?: (item: Option) => void;
}

export const Chips: React.FC<Props> = (props: Props) => {
  const { className = '', items } = props;

  return (
    <ul className={`${styles.list} ${className}`}>
      {items.map((item) => (
        <li className={styles.item}>
          <Chip key={item.value} item={item} onRemove={props.onRemove} />
        </li>
      ))}
    </ul>
  );
};
