import React from 'react';
import { Icon } from 'factor';
import { Link } from 'react-router-dom';

import styles from './SelectType.module.scss';

const list = [
  {
    title: 'Matched Audience',
    icon: <Icon name="Matched" />,
    url: '/matched',
  },
  {
    title: 'Campaign Audience',
    icon: <Icon name="Campaign" />,
    url: '/campaign',
  },
  {
    title: 'Segmented Audience',
    icon: <Icon name="Segmented" />,
    url: '/segmented',
  },
  {
    title: 'Retargeted Audience',
    icon: <Icon name="Retargeted" />,
    url: '/retargeted',
  },
  {
    title: 'Geo-farmed Audience',
    icon: <Icon name="Geo" />,
    url: '/geo-farmed',
  },
];

const SelectType = () => (
  <div className={styles.container}>
    <ul className={styles.list}>
      {list.map((i) => (
        <li className={styles.item} key={i.title}>
          <Link to={i.url} className={`block ${styles.link}`}>
            <div className={`${styles.linkIcon}`}>{i.icon}</div>
            <h4 className={styles.linkTitle}>{i.title}</h4>
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default SelectType;
