import React, { Component } from 'react';
import { Select, TextField, Button, Icon } from 'factor';

import styles from './styles.module.scss';

import { Sidebar } from '../../components/Sidebar';
import { testOptions } from '../../components/consts';
import { MainContent } from '../../components/MainContent';
import { TestTable } from '../../components/Table';
import { Layout } from '../../components/Layout';
import { Option } from '../../models/Option';
import { DatePicker } from '../../components/datePicker';
import { SidebarFooter } from '../../components/sidebarFooter';
import { ReactComponent as LocationIcon } from './img/geo/location.svg';
import { ReactComponent as PolygonIcon } from './img/geo/polygon.svg';
import { ReactComponent as ZipIcon } from '../../svg/zip.svg';
import { Chips } from '../../components/Chips';

const CHIPS = [
  {
    label: '1210 - Florida - 10',
    value: '1210 - Florida - 10',
    className: '_include',
    icon: <LocationIcon />,
  },
  { label: '10001', value: '10001', className: '_include', icon: <PolygonIcon /> },
  {
    label: '3406 New Jearsey - 06',
    value: '3406 New Jearsey - 06',
    className: '_include',
    icon: <ZipIcon />,
  },
  {
    label: '3406 New Jearsey - 07',
    value: '3406 New Jearsey - 07',
    className: '_include',
    icon: <ZipIcon />,
  },
  { label: '10002', value: '10002', className: '_exclude', icon: <ZipIcon /> },
];

interface State {
  country: Option | null;
  state: Option[];
  dwellTime: string;
}

export class GeofarmedAudience extends Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      country: null,
      state: [],
      dwellTime: '',
    };
  }

  handleTextFieldChange = (label: string) => (v: string) => {
    this.setState((prevState) => ({
      ...prevState,
      [label]: v,
    }));
  };

  handleSelectChange = (label: string) => (v: Option) => {
    this.setState((prevState) => ({
      ...prevState,
      [label]: v,
    }));
  };

  handleMultiSelectChange = (label: string) => (v: Option[]) => {
    this.setState((prevState) => ({
      ...prevState,
      [label]: v,
    }));
  };

  render() {
    const { country, state, dwellTime } = this.state;

    const sidebar = (
      <Sidebar
        header={{
          title: 'Geo-farmed Audience',
          icon: <Icon name="Geo" />,
        }}
      >
        <DatePicker className={styles.dateRange} />

        <TextField
          className="mb-3"
          value={dwellTime}
          onChange={this.handleTextFieldChange('dwellTime')}
          label="Dwell time (min)"
          withBorder
        />

        <div className="row mb-3">
          <div className="col-6">
            <Select
              className={`${styles.select} _left`}
              label="Country"
              placeholder="Country"
              options={testOptions}
              value={country}
              onChange={this.handleSelectChange('country')}
            />
          </div>
          <div className="col-6">
            <Select
              className={`${styles.select} _right`}
              label="State"
              placeholder="State"
              options={testOptions}
              value={state}
              onChange={this.handleMultiSelectChange('state')}
              isMulti
              allSelectable
            />
          </div>
        </div>
        <div className={styles.addButtons}>
          <Button className={`btn-round _gray ${styles.addBtn}`}>
            <span className="btn-round__prefix">+</span>
            Radius
          </Button>
          <Button className={`btn-round _gray ${styles.addBtn}`}>
            <span className="btn-round__prefix">+</span>
            Polygon
          </Button>
        </div>
        <Chips className={styles.chips} items={CHIPS} />
        <Button className="btn-square _conflower-blue mb-3 align-self-start">
          Select on the Map
        </Button>
        <SidebarFooter hasAudienceNameField submit={{ label: 'Add Geo-farmed Audience' }} />
      </Sidebar>
    );

    return (
      <Layout sidebar={sidebar}>
        <MainContent>
          <TestTable />
        </MainContent>
      </Layout>
    );
  }
}
