import React, { Component } from 'react';
import { Dialog, DialogContent, Select, Button } from 'factor';
import { Range } from 'react-range';

import { testOptions, locationOptions } from '../../consts';

import styles from './Expand.module.scss';

class Expand extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      location: null,
      locationSpecifics: null,
      multiplier: {
        min: 0,
        max: 10,
        step: 1,
        values: [2],
      },
    };
  }

  render() {
    const { show, close } = this.props;

    const { location, locationSpecifics, multiplier } = this.state;

    return (
      <Dialog className={styles.dialog} open={show} onClickOutside={close}>
        <DialogContent>
          <button type="button" className={`btn-close ${styles.close}`} onClick={close} />
          <div className={styles.row}>
            <Select
              label="Location"
              showControlLabel
              placeholder="Select a location type"
              options={locationOptions}
              value={location}
              onChange={(v: any) => this.setState({ location: v })}
            />
          </div>
          <div className={styles.row}>
            <Select
              label="Location Specifics"
              showControlLabel
              placeholder="Select"
              options={testOptions}
              value={locationSpecifics}
              onChange={(v: any) => this.setState({ locationSpecifics: v })}
              isMulti
              multiPlaceholder={(length: any) =>
                `${length} Cit${length > 1 ? 'ies' : 'y'} Selected`
              }
              allSelectable
            />
          </div>
          <Range
            min={multiplier.min}
            max={multiplier.max}
            step={multiplier.step}
            values={multiplier.values}
            onChange={(values) =>
              this.setState({
                multiplier: {
                  ...multiplier,
                  values,
                },
              })
            }
            renderTrack={({ props, children }) => (
              /* eslint-disable */
              <div className={`${styles.track} js-track`} {...props} style={{ ...props.style }}>
                {children}
              </div>
            )}
            renderThumb={({ props }) => {
              return (
                <div className={styles.slider} {...props} style={{ ...props.style }}>
                  <div className={styles.value}>{props['aria-valuenow']}%</div>
                </div>
                /* eslint-enable */
              );
            }}
          />
          <p className={`text-2 ${styles.p}`}>Discrepancy Allowance for New Audience</p>
          <div className={styles.buttons}>
            <Button className="btn-square _conflower-blue" onClick={close}>
              Cancel
            </Button>
            <Button className="btn-square _filled _conflower-blue" onClick={close}>
              Confirm
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default Expand;
