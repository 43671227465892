import React from 'react';
import { Switch, Button } from 'factor';

import styles from './styles.module.scss';

import exampleImage2 from '../../img/example/example_logo_2.png';
import exampleImage1 from '../../img/example/example_logo_1.png';
import { SearchList } from '../searchList';

const SWITCH_LIST = ['OR', 'AND'];

interface Item {
  imageSrc: string;
  title: string;
}

interface State {
  items: Item[];
  switches: boolean[];
  isAddButtonClicked: boolean;
}

export class Group extends React.Component<any, State> {
  searchField!: HTMLElement;

  constructor(props: any) {
    super(props);
    this.state = {
      items: [
        { imageSrc: exampleImage2, title: 'Automobile Repair 1 ($0.50)' },
        { imageSrc: exampleImage1, title: 'Automobile Repair 2 ($0.50)' },
      ],
      switches: [false],
      isAddButtonClicked: false,
    };
  }

  handleSwitchChange = (k: number) => () => {
    const { switches } = this.state;
    switches[k] = !switches[k];
    this.setState({ switches });
  };

  showSearchField = () => {
    this.setState({ isAddButtonClicked: true }, () => {
      if (this.searchField) {
        this.searchField.focus();
      }
    });
  };

  getSearchFieldRef = (el: HTMLElement) => {
    this.searchField = el;
  };

  render() {
    const { items, switches, isAddButtonClicked } = this.state;

    return (
      <div className={styles.container}>
        <button type="button" className={`btn-close _1 ${styles.remove}`} />
        {items.map((i: Item, k: number) => {
          return (
            <React.Fragment key={`${i.title}`}>
              <div className={styles.item}>
                <div className={styles.itemImage}>
                  <img src={i.imageSrc} alt={i.title} />
                </div>
                <h4 className={styles.itemTitle}>{i.title}</h4>
                <button type="button" className={`btn-close _1 ${styles.itemRemove}`} />
              </div>
              {k + 1 !== items.length && (
                <Switch
                  className={styles.switch}
                  list={SWITCH_LIST}
                  value={switches[k]}
                  onChange={this.handleSwitchChange(k)}
                />
              )}
            </React.Fragment>
          );
        })}
        {!isAddButtonClicked ? (
          <Button
            className={`btn-round _conflower-blue _plus ${styles.add}`}
            onClick={this.showSearchField}
          >
            <span className="btn-round__prefix">+</span>
            Add Segment
          </Button>
        ) : (
          <SearchList getSearchFieldRef={this.getSearchFieldRef} />
        )}
      </div>
    );
  }
}
