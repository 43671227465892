import React from 'react';
import { Table, Checkbox, Button, Icon, ButtonCircle, Select } from 'factor';
import { connect } from 'react-redux';

import styles from './Table.module.scss';

import { getRowClassName } from '../helpers';

import { tableActions } from '../../store/table/actions';

import SelectedCampaigns from './SelectedCampaigns';
import Expand from './Expand';

import { testOptions } from '../consts';
import { AppState } from '../../store';

class TestTableComponent extends React.PureComponent<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      showExpandModal: false,
      filterColumns: null,
    };
  }

  getHeader = () => {
    return [
      { label: '', className: '_checkbox' },
      { label: 'Audience Name', className: '_audience-name', sortingKey: 'audienceName' },
      { label: 'eCPM', className: '_ecpm', sortingKey: 'ecpm' },
      { label: 'Match Rate', className: '_match-rate', sortingKey: 'matchRate' },
      { label: 'White List', className: '_white-list', sortingKey: 'whiteList' },
      { label: 'Black List', className: '_black-list', sortingKey: 'blackList' },
      { label: 'Expand', className: styles.tdExpand },
    ];
  };

  getBody = () => {
    const { tableData } = this.props;
    const { data } = tableData;

    const { showExpandModal } = this.state;

    const selectedLength = data.filter((i: any) => i.selected).length;

    return [
      {
        key: (d: any) =>
          d.total ? (
            <Checkbox
              className={selectedLength && !d.selectedAll ? '_dirty' : ''}
              checked={d.selectedAll}
              name={`checkbox-id-${d.id}`}
            />
          ) : (
            <Checkbox checked={d.selected} name={`checkbox-id-${d.id}`} />
          ),
        className: '_checkbox',
      },
      {
        key: (d: any) => {
          if (d.total) {
            return <span className="table__cell-total">Total</span>;
          }
          return d.audienceName;
        },
        className: '_audience-name',
      },
      { key: 'ecpm', className: '_number _ecpm' },
      { key: 'matchRate', className: '_number _match-rate' },
      { key: 'whiteList', className: '_number _white-list' },
      { key: 'blackList', className: '_number _black-list' },
      {
        key: (d: any) =>
          !d.total && (
            <button
              type="button"
              className={styles.expand}
              onClick={() => this.setState({ showExpandModal: !showExpandModal })}
            >
              &harr;
            </button>
          ),
        className: styles.tdExpand,
      },
    ];
  };

  toggleSelect = (d: any) => {
    const { getData, tableData: oldTableData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;
    const tableFirstRow = newData.unsortedData;

    const selectedRowData = tableData.find((i: any) => i.id === d.id);
    selectedRowData.selected = !selectedRowData.selected;
    selectedRowData.rowClassName = getRowClassName(
      selectedRowData.rowClassName,
      '_selected',
      selectedRowData.selected,
    );

    newData.unsortedData = {
      ...tableFirstRow,
      selectedAll: tableData.filter((i: any) => i.selected).length === tableData.length,
    };
    newData.data = tableData.map((i: any) => {
      return i.id === d.id ? { ...i } : i;
    });

    getData(newData);
  };

  toggleSelectAll = () => {
    const { getData, tableData: oldTableData } = this.props;

    const newData = JSON.parse(JSON.stringify(oldTableData));

    const tableData = newData.data;
    const tableFirstRow = newData.unsortedData;

    const selectedLength = tableData.filter((i: any) => i.selected).length;

    if (newData.selectedAll || selectedLength > 0) {
      newData.unsortedData = {
        ...tableFirstRow,
        selectedAll: false,
      };
      newData.data = tableData.map((i: any) => {
        /* eslint-disable */
        i.selected = false;
        i.rowClassName = getRowClassName(i.rowClassName, '_selected', false);
        /* eslint-enable */
        return { ...i };
      });
    } else {
      newData.unsortedData = {
        ...tableFirstRow,
        selectedAll: true,
      };
      newData.data = tableData.map((i: any) => {
        /* eslint-disable */
        i.selected = true;
        i.rowClassName = getRowClassName(i.rowClassName, '_selected', true);
        /* eslint-enable */
        return { ...i };
      });
    }
    getData(newData);
  };

  rowClickHandler = (e: any, data: any) => {
    const { tagName } = e.target;
    const elType = e.target.getAttribute('type');

    if (tagName === 'INPUT' && elType === 'checkbox') {
      if (data.total) {
        this.toggleSelectAll();
      } else {
        this.toggleSelect(data);
      }
    }
  };

  render() {
    const { tableData } = this.props;

    const { showExpandModal, filterColumns } = this.state;

    const selectedLength = tableData.data.filter((i: any) => i.selected).length;

    return (
      <div>
        <div className="d-flex align-items-center mb-3">
          <ButtonCircle outline iconName="CSV" className="_size-20 ml-auto mr-3" />
          <Select
            className={`${styles.select}`}
            options={testOptions}
            onChange={(value: any) => {
              this.setState({
                filterColumns: value,
              });
            }}
            value={filterColumns}
            placeholder="Select Columns"
            isMulti
            multiPlaceholder={(length: any) => `${length} Column${length > 1 ? 's' : ''} Selected`}
            allSelectable
            dnd
          />
        </div>
        {selectedLength > 0 && (
          <div className="d-flex align-items-center mb-3">
            <SelectedCampaigns />
            <Button className={`btn-round _conflower-blue mr-2 ${styles.actions}`}>
              <span className="btn-round__prefix">
                <Icon name="Expand" />
              </span>
              Actions
            </Button>
            <ButtonCircle outline className="_size-20 ml-auto" iconName="Copy" />
            <ButtonCircle outline className="_size-20" iconName="CSV" />
          </div>
        )}
        <Table
          className={styles.table}
          header={this.getHeader()}
          body={this.getBody()}
          data={tableData.data}
          unsortedData={[tableData.unsortedData]}
          paging={null}
          fixedHeader
          offsetTop={70}
          rowKeyExtractor={(data: any) => data.id}
          rowClickHandler={this.rowClickHandler}
          windowFreeResizeEvent
        />

        <Expand show={showExpandModal} close={() => this.setState({ showExpandModal: false })} />
      </div>
    );
  }
}

const mapState = (state: AppState) => {
  return {
    tableData: state.table.tableData,
  };
};

const mapActions = {
  getData: tableActions.getData,
};

const TestTable = connect(mapState, mapActions)(TestTableComponent);

export { TestTable };
