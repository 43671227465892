import React from 'react';
import { Icon, Tooltip } from 'factor';
import styles from './FileUploader.module.scss';

interface FileUploaderProps {
  className?: string;
  browseFileButtonTitle?: string;
  onFileUploaded?: (fileList: FileList) => any;
  tooltipParams: {
    label: string;
    labelMaxWidth?: number;
    position?: string;
  };
  isMultiple?: boolean;
  isError?: boolean;
  error?: string;
}

class FileUploader extends React.Component<FileUploaderProps> {
  private fileContainerElement: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.fileContainerElement = React.createRef();
  }

  handleDropInput = (event: React.DragEvent<HTMLDivElement>) => {
    const { onFileUploaded } = this.props;
    event.stopPropagation();
    event.preventDefault();

    if (this.fileContainerElement.current) {
      this.fileContainerElement.current.classList.remove(styles.dropable);
    }

    if (onFileUploaded) {
      onFileUploaded(event.dataTransfer.files);
    }
  };

  handleBrowseInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onFileUploaded } = this.props;

    if (onFileUploaded && event.target.files) {
      onFileUploaded(event.target.files);
    }
  };

  handleDragOver = (event: any) => {
    event.preventDefault();

    if (this.fileContainerElement.current) {
      this.fileContainerElement.current.classList.add(styles.dropable);
    }
  };

  handleDragEnd = (event: any) => {
    event.preventDefault();
    if (this.fileContainerElement.current) {
      this.fileContainerElement.current.classList.remove(styles.dropable);
    }
  };

  render() {
    const { className, browseFileButtonTitle, tooltipParams, isMultiple, isError } = this.props;

    const fullClassNames = [styles.container];
    if (isError) {
      fullClassNames.push(styles.error);
    }
    if (className) {
      fullClassNames.push(className);
    }

    const label = (
      <label
        className={`btn-square _conflower-blue ${styles.browseButton}`}
        htmlFor={browseFileButtonTitle}
      >
        <span className="btn-content">
          <span>Upload</span>
          <span className="btn-content__icon">
            <Icon name="Upload" />
          </span>
        </span>
      </label>
    );

    return (
      <div
        className={fullClassNames.join(' ')}
        onDrop={this.handleDropInput}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragEnd}
        ref={this.fileContainerElement}
      >
        <input
          className={styles.fileInput}
          type="file"
          id={browseFileButtonTitle}
          onChange={this.handleBrowseInput}
          value=""
          multiple={isMultiple}
        />
        <div className="d-flex align-items-center mb-2">
          {tooltipParams ? (
            <Tooltip label={tooltipParams.label} auto={!tooltipParams.position}>
              {label}
            </Tooltip>
          ) : (
            label
          )}
          <button type="button" className={styles.sample}>
            Sample File
          </button>
        </div>
        <div className={styles.text}>Drag and Drop List</div>
        <p className={styles.error}>Allow Only to Upload CSV, XLS, XLSX</p>
      </div>
    );
  }
}

export default FileUploader;
