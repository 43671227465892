import React from 'react';
import { Select, Checkbox, Icon } from 'factor';

import styles from './CampaignAudience.module.scss';
import { Sidebar } from '../../components/Sidebar';
import { MainContent } from '../../components/MainContent';
import { TestTable } from '../../components/Table';
import { Layout } from '../../components/Layout';
import { ComplexOption } from './components/ComplexOption/ComplexOption';
import { ReactOption } from '../../models/Option';
import { SidebarFooter } from '../../components/sidebarFooter';

interface Checkbox {
  title: string;
  checked: boolean;
}

const CAMPAIGN_PARAMS_LIST = [
  { label: 'Impressions', value: '2,230' },
  { label: 'Clicks', value: '42,441' },
  { label: 'Complete Video', value: '1,257' },
];

const CAMPAIGNS_OPTIONS = [
  {
    value: '0',
    label: 'Campaign 1',
    reactLabel: <ComplexOption title="Campaign 1" list={CAMPAIGN_PARAMS_LIST} />,
  },
  {
    value: '1',
    label: 'Campaign 2',
    reactLabel: <ComplexOption title="Campaign 2" list={CAMPAIGN_PARAMS_LIST} />,
  },
  {
    value: '2',
    label: 'Campaign 3',
    reactLabel: <ComplexOption title="Campaign 3" list={CAMPAIGN_PARAMS_LIST} />,
  },
  {
    value: '3',
    label: 'Campaign 4',
    reactLabel: <ComplexOption title="Campaign 4" list={CAMPAIGN_PARAMS_LIST} />,
  },
];

const EVENT_PARAMS_LIST = [
  { label: 'Unique', value: '2,230' },
  { label: 'Lasted', value: '42,441' },
  { label: 'Created', value: '12/21/2019' },
];

const EVENTS_OPTIONS = [
  {
    value: '0',
    label: 'Event 1',
    reactLabel: <ComplexOption title="Event 1" list={EVENT_PARAMS_LIST} />,
  },
  {
    value: '1',
    label: 'Event 2',
    reactLabel: <ComplexOption title="Event 2" list={EVENT_PARAMS_LIST} />,
  },
  {
    value: '2',
    label: 'Event 3',
    reactLabel: <ComplexOption title="Event 3" list={EVENT_PARAMS_LIST} />,
  },
  {
    value: '3',
    label: 'Event 4',
    reactLabel: <ComplexOption title="Event 4" list={EVENT_PARAMS_LIST} />,
  },
];

interface State {
  campaign: ReactOption | null;
  eventName: ReactOption[];
  checkboxes: {
    title: string;
    checked: boolean;
  }[];
}

export class CampaignAudience extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      campaign: null,
      eventName: [],
      checkboxes: [
        {
          title: 'Impressions',
          checked: true,
        },
        {
          title: 'Clicks',
          checked: false,
        },
        {
          title: 'Conversion',
          checked: false,
        },
      ],
    };
  }

  handleCampaignSelectChange = (v: ReactOption) => this.setState({ campaign: v });

  handleEventSelectChange = (v: ReactOption[]) => this.setState({ eventName: v });

  handleCheckboxChange = (title: string) => () => {
    const { checkboxes } = this.state;

    const checkbox = checkboxes.filter((i: Checkbox): boolean => i.title === title)[0];

    if (checkbox) {
      checkbox.checked = !checkbox.checked;
      this.setState({ checkboxes });
    }
  };

  render() {
    const { campaign, eventName, checkboxes } = this.state;

    const sidebar = (
      <Sidebar
        header={{
          title: 'Campaign Audience',
          icon: <Icon name="Campaign" />,
        }}
      >
        <div className={styles.row}>
          <Select
            label="Campaigns"
            placeholder="Select Campaigns"
            options={CAMPAIGNS_OPTIONS}
            value={campaign}
            isSearchable
            onChange={this.handleCampaignSelectChange}
          />
        </div>
        <div className={styles.row}>
          <div className="text-label">* Select Audience of</div>
          <ul className={styles.checkboxes}>
            {checkboxes.map((i: Checkbox) => (
              <li className={styles.checkbox} key={i.title}>
                <Checkbox
                  label={i.title}
                  name={i.title}
                  checked={i.checked}
                  onChange={this.handleCheckboxChange(i.title)}
                />
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.row}>
          <Select
            label="Events"
            placeholder="Select Events"
            options={EVENTS_OPTIONS}
            value={eventName}
            isMulti
            allSelectable
            isSearchable
            onChange={this.handleEventSelectChange}
          />
        </div>
        <SidebarFooter hasAudienceNameField submit={{ label: 'Add Campaign Audience' }} />
      </Sidebar>
    );

    return (
      <Layout sidebar={sidebar}>
        <MainContent>
          <TestTable />
        </MainContent>
      </Layout>
    );
  }
}
